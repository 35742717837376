import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Contact } from './types';

interface ExportOptions {
  includeReport?: boolean;
  originalContacts?: Contact[];
  onProgress?: (progress: number) => void;
}

export async function exportContacts(
  contacts: Contact[],
  format: string,
  options: ExportOptions = {}
): Promise<void> {
  const { onProgress = () => {} } = options;
  onProgress(10);

  const filename = `contacts-${new Date().toISOString().split('T')[0]}`;
  
  switch (format) {
    case 'csv':
      await exportToCSV(contacts, filename);
      break;
    case 'xlsx':
      await exportToExcel(contacts, filename, options);
      break;
    case 'vcf':
      await exportToVCard(contacts, filename);
      break;
    default:
      throw new Error('Unsupported export format');
  }

  onProgress(100);
}

async function exportToCSV(contacts: Contact[], filename: string) {
  const csv = Papa.unparse(contacts.map(formatContactForExport));
  downloadFile(csv, `${filename}.csv`, 'text/csv');
}

async function exportToExcel(
  contacts: Contact[],
  filename: string,
  options: ExportOptions
) {
  const workbook = XLSX.utils.book_new();

  // Main contacts sheet
  const contactsSheet = XLSX.utils.json_to_sheet(
    contacts.map(formatContactForExport)
  );
  XLSX.utils.book_append_sheet(workbook, contactsSheet, 'Contacts');

  // Add merge report if requested
  if (options.includeReport && options.originalContacts) {
    const reportData = generateMergeReport(contacts, options.originalContacts);
    const reportSheet = XLSX.utils.json_to_sheet(reportData);
    XLSX.utils.book_append_sheet(workbook, reportSheet, 'Merge Report');
  }
  
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  downloadFile(
    excelBuffer,
    `${filename}.xlsx`,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    true
  );
}

async function exportToVCard(contacts: Contact[], filename: string) {
  const vCards = contacts.map((contact) => {
    const name = [contact.firstName, contact.lastName].filter(Boolean).join(' ');
    return `BEGIN:VCARD
VERSION:3.0
FN:${name}
${contact.email ? `EMAIL;TYPE=HOME:${contact.email}` : ''}
${contact.workEmail ? `EMAIL;TYPE=WORK:${contact.workEmail}` : ''}
${contact.phone ? `TEL;TYPE=HOME:${contact.phone}` : ''}
${contact.workPhone ? `TEL;TYPE=WORK:${contact.workPhone}` : ''}
${contact.jobTitle ? `TITLE:${contact.jobTitle}` : ''}
${contact.company ? `ORG:${contact.company}` : ''}
${contact.notes ? `NOTE:${contact.notes}` : ''}
END:VCARD`;
  }).join('\n');

  downloadFile(vCards, `${filename}.vcf`, 'text/vcard');
}

function generateMergeReport(contacts: Contact[], originalContacts: Contact[]) {
  const report: any[] = [];
  const emailMap = new Map<string, Contact[]>();
  const nameMap = new Map<string, Contact[]>();

  // Group original contacts by email and name
  originalContacts.forEach(contact => {
    if (contact.email) {
      const existing = emailMap.get(contact.email) || [];
      emailMap.set(contact.email, [...existing, contact]);
    }
    if (contact.firstName || contact.lastName) {
      const name = `${contact.firstName || ''} ${contact.lastName || ''}`.trim().toLowerCase();
      if (name) {
        const existing = nameMap.get(name) || [];
        nameMap.set(name, [...existing, contact]);
      }
    }
  });

  // Generate report entries
  contacts.forEach(merged => {
    const matchingByEmail = merged.email ? emailMap.get(merged.email) || [] : [];
    const name = `${merged.firstName || ''} ${merged.lastName || ''}`.trim().toLowerCase();
    const matchingByName = name ? nameMap.get(name) || [] : [];
    
    const allMatches = [...new Set([...matchingByEmail, ...matchingByName])];
    
    if (allMatches.length > 1) {
      report.push({
        'Merged Name': `${merged.firstName || ''} ${merged.lastName || ''}`.trim(),
        'Merged Email': merged.email || '',
        'Original Sources': allMatches.map(m => m.source).filter(Boolean).join(', '),
        'Number of Duplicates': allMatches.length - 1,
        'Original Data': allMatches
          .map(m => `${m.source}: ${formatContactDetails(m)}`)
          .join('\n'),
      });
    }
  });

  return report;
}

function formatContactDetails(contact: Contact): string {
  const details = [];
  if (contact.email) details.push(`Email: ${contact.email}`);
  if (contact.workEmail) details.push(`Work Email: ${contact.workEmail}`);
  if (contact.phone) details.push(`Phone: ${contact.phone}`);
  if (contact.workPhone) details.push(`Work Phone: ${contact.workPhone}`);
  if (contact.jobTitle) details.push(`Title: ${contact.jobTitle}`);
  if (contact.company) details.push(`Company: ${contact.company}`);
  return details.join(', ');
}

function formatContactForExport(contact: Contact) {
  return {
    'First Name': contact.firstName || '',
    'Last Name': contact.lastName || '',
    'Email': contact.email || '',
    'Work Email': contact.workEmail || '',
    'Phone': contact.phone || '',
    'Work Phone': contact.workPhone || '',
    'Job Title': contact.jobTitle || '',
    'Company': contact.company || '',
    'Group': contact.group || '',
    'Notes': contact.notes || '',
    'Source': contact.source || '',
  };
}

function downloadFile(
  content: string | ArrayBuffer,
  filename: string,
  mimeType: string,
  isBinary = false
) {
  const blob = isBinary
    ? new Blob([content], { type: mimeType })
    : new Blob([content as string], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}