import { MergeIcon } from 'lucide-react';

export function Footer() {
  return (
    <footer className="border-t py-6 bg-background">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <MergeIcon className="h-5 w-5" />
            <span className="font-semibold">Contacts Organizer</span>
          </div>
          <div className="text-sm text-muted-foreground">
            © {new Date().getFullYear()} Contacts Organizer. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}