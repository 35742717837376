import { useState } from 'react';
import { Contact } from '@/lib/types';
import { FileUpload } from './file-upload';
import { ContactsView } from './contacts-view';
import { ProcessingScreen } from './processing-screen';
import { parseContactFile } from '@/lib/contact-parser';
import { mergeContacts } from '@/lib/contact-merger';
import { useToast } from '@/hooks/use-toast';

type DashboardState = 'upload' | 'processing' | 'view';

export function ContactDashboard() {
  const { toast } = useToast();
  const [state, setState] = useState<DashboardState>('upload');
  const [originalContacts, setOriginalContacts] = useState<Contact[]>([]);
  const [mergedContacts, setMergedContacts] = useState<Contact[]>([]);

  const handleFileUpload = async (file: File) => {
    setState('processing');
    try {
      const parsedFile = await parseContactFile(file);
      setOriginalContacts(parsedFile.contacts);

      // Process the contacts
      setTimeout(() => {
        const merged = mergeContacts(parsedFile.contacts);
        setMergedContacts(merged);
        setState('view');
      }, 3000);
    } catch (error) {
      toast({
        title: 'Error processing file',
        description: error instanceof Error ? error.message : 'Unknown error',
        variant: 'destructive',
      });
      setState('upload');
    }
  };

  const handleStartNew = () => {
    setState('upload');
    setOriginalContacts([]);
    setMergedContacts([]);
  };

  if (state === 'upload') {
    return <FileUpload onFileSelect={handleFileUpload} />;
  }

  if (state === 'processing') {
    return <ProcessingScreen totalContacts={originalContacts.length} onComplete={() => setState('view')} />;
  }

  return (
    <ContactsView 
      originalContacts={originalContacts}
      mergedContacts={mergedContacts}
      onStartNew={handleStartNew}
    />
  );
}