import { titleCase } from './string-utils';

const COMMON_SUFFIXES = [
  'Jr', 'Sr', 'II', 'III', 'IV', 'V',
  'MD', 'PhD', 'JD', 'DDS', 'MBA',
  'CSC', 'FCP', 'FACOG', 'MSCP',
  'MPH', 'EdD', 'PsyD', 'DPhil',
  'Dr'
].map(suffix => ({
  full: suffix,
  pattern: new RegExp(`\\b${suffix}\\b|\\b${suffix}\\.`, 'i')
}));

interface ParsedName {
  firstName: string;
  lastName: string;
  middleName?: string;
  suffix?: string;
  preferredName?: string;
}

export function parseComplexName(fullName: string): ParsedName {
  // Remove extra spaces and standardize separators
  let cleanName = fullName.trim().replace(/\s+/g, ' ');
  let suffix = '';
  let preferredName = '';

  // Handle empty or null names
  if (!cleanName) {
    return {
      firstName: '',
      lastName: '',
    };
  }

  // Extract preferred name in parentheses or after nickname indicators
  const preferredMatch = cleanName.match(/[\(\"]([^\)\"]+)[\)\"]|\s+["']([^"']+)["']|\s+\((.*?)\)/);
  if (preferredMatch) {
    preferredName = (preferredMatch[1] || preferredMatch[2] || preferredMatch[3]).trim();
    cleanName = cleanName.replace(/\s*[\(\"].*?[\)\"]|\s+["'].*?["']|\s+\(.*?\)/, '').trim();
  }

  // Extract suffix if present
  for (const { pattern, full } of COMMON_SUFFIXES) {
    const match = cleanName.match(pattern);
    if (match) {
      suffix = full; // Use standardized form
      cleanName = cleanName.replace(pattern, '').trim();
      break;
    }
  }

  // Handle hyphenated last names (e.g., "Garcia-Chapa")
  const hyphenatedMatch = cleanName.match(/(\S+)-(\S+)$/);
  if (hyphenatedMatch) {
    const hyphenatedLastName = hyphenatedMatch[0];
    cleanName = cleanName.replace(hyphenatedLastName, `__${hyphenatedLastName}__`);
  }

  // Handle comma-separated formats (e.g., "Lastname, Jr., Firstname")
  if (cleanName.includes(',')) {
    const parts = cleanName.split(',').map(p => p.trim());
    const lastName = parts[0].replace(/__(.+)__/, '$1'); // Restore hyphenated name
    const remainingParts = parts.slice(1).join(' ').trim();
    
    return {
      firstName: titleCase(remainingParts),
      lastName: titleCase(lastName),
      suffix: suffix || undefined,
      preferredName: preferredName || undefined
    };
  }

  // Handle regular format
  const parts = cleanName.split(' ');
  
  // If only one word, treat as last name
  if (parts.length === 1) {
    return {
      firstName: '',
      lastName: titleCase(parts[0].replace(/__(.+)__/, '$1')), // Restore hyphenated name
      suffix: suffix || undefined,
      preferredName: preferredName || undefined
    };
  }

  // Extract last name (handling hyphenated names)
  const lastName = parts.pop()?.replace(/__(.+)__/, '$1') || '';
  
  // Handle middle names and initials
  let firstName = '';
  let middleName = '';
  
  if (parts.length > 1) {
    // Check for middle initials or names
    const middleParts = parts.slice(1);
    const potentialMiddle = middleParts.join(' ');
    
    // If it looks like an initial (single letter or letter with period)
    if (middleParts.every(part => part.length === 1 || (part.length === 2 && part.endsWith('.')))) {
      middleName = middleParts.map(p => p.replace('.', '')).join(' ');
      firstName = parts[0];
    } else {
      // If middle part looks more like a name
      firstName = parts[0];
      middleName = potentialMiddle;
    }
  } else {
    firstName = parts[0];
  }

  // Special case: if we have a hyphenated last name but no first name
  if (!firstName && lastName.includes('-')) {
    const [first, last] = lastName.split('-');
    return {
      firstName: titleCase(first),
      lastName: titleCase(last),
      suffix: suffix || undefined,
      preferredName: preferredName || undefined
    };
  }

  return {
    firstName: titleCase(firstName),
    lastName: titleCase(lastName),
    middleName: middleName ? titleCase(middleName) : undefined,
    suffix: suffix || undefined,
    preferredName: preferredName || undefined
  };
}

export function formatFullName(parsedName: ParsedName): string {
  const parts = [parsedName.firstName];
  
  if (parsedName.middleName) {
    // If middle name is a single letter, add a period
    const middleName = parsedName.middleName.length === 1 
      ? `${parsedName.middleName}.`
      : parsedName.middleName;
    parts.push(middleName);
  }
  
  parts.push(parsedName.lastName);
  
  if (parsedName.suffix) {
    // Add comma before suffix for certain types
    const needsComma = ['Jr', 'Sr', 'III', 'IV'].includes(parsedName.suffix);
    parts.push(needsComma ? `, ${parsedName.suffix}` : parsedName.suffix);
  }
  
  let fullName = parts.join(' ');
  
  if (parsedName.preferredName) {
    fullName += ` (${parsedName.preferredName})`;
  }
  
  return fullName;
}

// Helper function to check if a name part is likely a middle initial
function isMiddleInitial(part: string): boolean {
  return part.length === 1 || (part.length === 2 && part.endsWith('.'));
}