import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileSpreadsheet, Upload } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

interface FileUploadProps {
  onFileSelect: (file: File) => void;
}

export function FileUpload({ onFileSelect }: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: false,
  });

  return (
    <div className="container mx-auto px-4 py-20">
      <div className="max-w-3xl mx-auto text-center space-y-8">
        <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-accent/10 text-accent text-sm font-medium">
          <FileSpreadsheet className="h-4 w-4" />
          Supports Excel, CSV & Google Sheets
        </div>

        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-gray-900 mb-6 max-w-4xl mx-auto tracking-tight">
          From Messy Contact Lists to{" "}
          <span className="text-[#563B95]">Organized Perfection</span>
        </h1>

        <p className="text-xl text-muted-foreground">
          Upload your contact lists from any source. We'll combine duplicates,
          standardize formats, and create a clean master list.
        </p>

        <Card
          {...getRootProps()}
          className={cn(
            "border-2 border-dashed p-12 transition-all duration-300",
            isDragActive
              ? "border-accent bg-accent/5"
              : selectedFile
              ? "border-accent bg-accent/5"
              : "border-muted-foreground/25 hover:border-accent hover:bg-accent/5"
          )}
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center gap-4">
            <FileSpreadsheet className="h-12 w-12 text-muted-foreground" />
            <div>
              <h3 className="text-lg font-semibold">
                {selectedFile
                  ? selectedFile.name
                  : isDragActive
                  ? "Drop your file here"
                  : "Drag and drop your contact list"}
              </h3>
              <p className="text-sm text-muted-foreground mt-1">
                {selectedFile
                  ? "Ready to process"
                  : "Works with Excel, CSV & Google Sheets exports"}
              </p>
            </div>
          </div>
        </Card>

        {selectedFile && (
          <Button
            size="lg"
            onClick={() => onFileSelect(selectedFile)}
            className="h-12 px-8 text-lg"
          >
            <Upload className="mr-2 h-5 w-5" />
            Process Contacts
          </Button>
        )}

        <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
          <div className="text-center p-6">
            <div className="w-12 h-12 rounded-2xl bg-[#563B95]/10 text-[#563B95] flex items-center justify-center mx-auto mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-file-spreadsheet w-6 h-6"
              >
                <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
                <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
                <path d="M8 13h2"></path>
                <path d="M14 13h2"></path>
                <path d="M8 17h2"></path>
                <path d="M14 17h2"></path>
              </svg>
            </div>
            <p className="text-gray-900 text-lg font-medium leading-normal mb-2">
              Multi-Source Import
            </p>
            <p className="text-gray-600 text-base font-normal leading-normal">
              Import from multiple files &amp; sheets at once. Supports all
              major formats.
            </p>
          </div>
          <div className="text-center p-6">
            <div className="w-12 h-12 rounded-2xl bg-[#563B95]/10 text-[#563B95] flex items-center justify-center mx-auto mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-zap w-6 h-6"
              >
                <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z"></path>
              </svg>
            </div>
            <p className="text-gray-900 text-lg font-medium leading-normal mb-2">
              Smart Matching
            </p>
            <p className="text-gray-600 text-base font-normal leading-normal">
              Automatically detect &amp; merge duplicates with advanced
              algorithms.
            </p>
          </div>
          <div className="text-center p-6">
            <div className="w-12 h-12 rounded-2xl bg-[#563B95]/10 text-[#563B95] flex items-center justify-center mx-auto mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-shield w-6 h-6"
              >
                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
              </svg>
            </div>
            <p className="text-gray-900 text-lg font-medium leading-normal mb-2">
              Privacy First
            </p>
            <p className="text-gray-600 text-base font-normal leading-normal">
              Your data never leaves your browser. Process everything locally.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
