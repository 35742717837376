import { useState } from 'react';
import { Contact } from '@/lib/types';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { ContactsGrid } from './contacts-grid';
import { ExportDialog } from './export-dialog';
import { FileSpreadsheet, Users, GitMerge, Check, Download } from 'lucide-react';

interface ContactsViewProps {
  originalContacts: Contact[];
  mergedContacts: Contact[];
  onStartNew: () => void;
}

export function ContactsView({ 
  originalContacts, 
  mergedContacts,
  onStartNew 
}: ContactsViewProps) {
  const [showExport, setShowExport] = useState(false);
  const [activeTab, setActiveTab] = useState('all');

  // Calculate stats
  const stats = {
    totalOriginal: originalContacts.length,
    totalMerged: mergedContacts.length,
    duplicatesRemoved: originalContacts.length - mergedContacts.length,
    sourcesCombined: new Set(originalContacts.map(c => c.source)).size
  };

  // Filter contacts based on active tab
  const filteredContacts = {
    all: mergedContacts,
    duplicates: mergedContacts.filter(c => c.source?.includes(',')),
    unique: mergedContacts.filter(c => !c.source?.includes(','))
  };

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold mb-2">Contact Management</h1>
          <p className="text-muted-foreground">
            Manage and export your cleaned contact list
          </p>
        </div>

        <div className="flex items-center gap-4">
          <Button variant="outline" onClick={onStartNew}>
            Start New Import
          </Button>
          <Button onClick={() => setShowExport(true)}>
            <Download className="mr-2 h-4 w-4" />
            Export Contacts
          </Button>
        </div>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-4 gap-4">
        <Card className="p-6 hover:shadow-md transition-shadow cursor-pointer" onClick={() => setActiveTab('all')}>
          <div className="flex items-center gap-4">
            <div className="p-3 bg-blue-50 dark:bg-blue-950 rounded-xl">
              <FileSpreadsheet className="h-6 w-6 text-blue-500" />
            </div>
            <div>
              <div className="text-2xl font-bold">{stats.totalOriginal}</div>
              <div className="text-sm text-muted-foreground">Original Contacts</div>
            </div>
          </div>
        </Card>

        <Card className="p-6 hover:shadow-md transition-shadow cursor-pointer" onClick={() => setActiveTab('all')}>
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-50 dark:bg-green-950 rounded-xl">
              <Users className="h-6 w-6 text-green-500" />
            </div>
            <div>
              <div className="text-2xl font-bold">{stats.totalMerged}</div>
              <div className="text-sm text-muted-foreground">Final Contacts</div>
            </div>
          </div>
        </Card>

        <Card className="p-6 hover:shadow-md transition-shadow cursor-pointer" onClick={() => setActiveTab('duplicates')}>
          <div className="flex items-center gap-4">
            <div className="p-3 bg-purple-50 dark:bg-purple-950 rounded-xl">
              <GitMerge className="h-6 w-6 text-purple-500" />
            </div>
            <div>
              <div className="text-2xl font-bold">{stats.duplicatesRemoved}</div>
              <div className="text-sm text-muted-foreground">Duplicates Removed</div>
            </div>
          </div>
        </Card>

        <Card className="p-6 hover:shadow-md transition-shadow cursor-pointer" onClick={() => setActiveTab('unique')}>
          <div className="flex items-center gap-4">
            <div className="p-3 bg-amber-50 dark:bg-amber-950 rounded-xl">
              <Check className="h-6 w-6 text-amber-500" />
            </div>
            <div>
              <div className="text-2xl font-bold">{stats.sourcesCombined}</div>
              <div className="text-sm text-muted-foreground">Sources Combined</div>
            </div>
          </div>
        </Card>
      </div>

      {/* Contacts Grid */}
      <Card>
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <div className="p-4 border-b">
            <div className="flex items-center justify-between">
              <TabsList>
                <TabsTrigger value="all" className="relative">
                  All Contacts
                  <Badge className="ml-2 bg-muted">{mergedContacts.length}</Badge>
                </TabsTrigger>
                <TabsTrigger value="duplicates">
                  Merged
                  <Badge className="ml-2 bg-muted">
                    {filteredContacts.duplicates.length}
                  </Badge>
                </TabsTrigger>
                <TabsTrigger value="unique">
                  Unique
                  <Badge className="ml-2 bg-muted">
                    {filteredContacts.unique.length}
                  </Badge>
                </TabsTrigger>
              </TabsList>
            </div>
          </div>

          <TabsContent value="all" className="m-0 p-4">
            <ContactsGrid 
              contacts={filteredContacts.all} 
              onExport={() => setShowExport(true)}
            />
          </TabsContent>
          
          <TabsContent value="duplicates" className="m-0 p-4">
            <ContactsGrid 
              contacts={filteredContacts.duplicates}
              onExport={() => setShowExport(true)}
            />
          </TabsContent>
          
          <TabsContent value="unique" className="m-0 p-4">
            <ContactsGrid 
              contacts={filteredContacts.unique}
              onExport={() => setShowExport(true)}
            />
          </TabsContent>
        </Tabs>
      </Card>

      <ExportDialog
        contacts={mergedContacts}
        originalContacts={originalContacts}
        stats={stats}
        open={showExport}
        onOpenChange={setShowExport}
      />
    </div>
  );
}