import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Contact, ContactFile } from './types';
import { parseComplexName } from './name-utils';
import { titleCase } from './string-utils';

export async function parseContactFile(file: File): Promise<ContactFile> {
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  let rawData: unknown[] = [];
  let sheetNames: string[] = [];
  let totalContacts = 0;

  if (fileExtension === 'csv') {
    rawData = await parseCSV(file);
  } else if (['xls', 'xlsx'].includes(fileExtension || '')) {
    const result = await parseExcel(file);
    rawData = result.data;
    sheetNames = result.sheetNames;
  } else {
    throw new Error('Unsupported file format');
  }

  const headers = Object.keys(rawData[0] || {});
  const contacts: Contact[] = [];

  // Process each row into contacts
  rawData.forEach((row: unknown, index) => {
    const typedRow = row as Record<string, string>;
    // Handle different list formats
    if ('Family' in typedRow) {
      // 50th Birthday List format - may contain multiple contacts per row
      const familyContacts = processFamilyRow(typedRow, file.name, index);
      contacts.push(...familyContacts);
      totalContacts += familyContacts.length;
    } else if ('Name' in typedRow) {
      // Friends Birthday List format - may contain multiple names per row
      const friendContacts = processFriendRow(typedRow, file.name, index);
      contacts.push(...friendContacts);
      totalContacts += friendContacts.length;
    } else if ('Last Name' in typedRow || 'First Name' in typedRow) {
      // YPO, Vanderbilt BoT, or Trive format - one contact per row
      const contact = processStandardRow(typedRow, file.name, index);
      if (contact) {
        contacts.push(contact);
        totalContacts++;
      }
    }
  });

  return {
    id: crypto.randomUUID(),
    name: file.name,
    contacts,
    headers,
    rawData,
    sheetNames,
    totalOriginalContacts: totalContacts
  };
}

async function parseCSV(file: File): Promise<unknown[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => resolve(results.data),
      error: (error) => reject(error),
    });
  });
}

async function parseExcel(file: File): Promise<{ data: unknown[]; sheetNames: string[] }> {
  const arrayBuffer = await file.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer);
  const allData: unknown[] = [];
  const sheetNames = workbook.SheetNames;

  sheetNames.forEach((sheetName) => {
    const worksheet = workbook.Sheets[sheetName];
    const sheetData = XLSX.utils.sheet_to_json(worksheet);
    
    // Add sheet name to each row for tracking
    const processedData = sheetData.map((row) => ({
      ...(row as object),
      '_sheet': sheetName,
    }));
    
    allData.push(...processedData);
  });

  return { data: allData, sheetNames };
}

function processFamilyRow(row: Record<string, string>, fileName: string, index: number): Contact[] {
  const contacts: Contact[] = [];
  const source = row['_sheet'] || fileName;

  // Process male contact if present
  if (row['Male First Name']) {
    contacts.push({
      id: `${fileName}-${index}-m`,
      firstName: titleCase(row['Male First Name']),
      lastName: titleCase(row['Last Name'] || row['Family'].replace('The ', '').replace(' Family', '')),
      email: row['Email Address'] || '',
      phone: row['Phone'] || '',
      source
    });
  }

  // Process female contact if present
  if (row['Female First Name']) {
    contacts.push({
      id: `${fileName}-${index}-f`,
      firstName: titleCase(row['Female First Name']),
      lastName: titleCase(row['Last Name'] || row['Family'].replace('The ', '').replace(' Family', '')),
      email: row['Email Address'] || '',
      phone: row['Phone'] || '',
      source
    });
  }

  return contacts;
}

function processFriendRow(row: Record<string, string>, fileName: string, index: number): Contact[] {
  const contacts: Contact[] = [];
  const source = row['_sheet'] || fileName;

  // Handle combined names (e.g., "Kendra & Taylor allen")
  const nameStr = row['Name'] || '';
  const names = nameStr.split(/\s*&\s*|\s*and\s*/i);

  names.forEach((name, i) => {
    const parsed = parseComplexName(name.trim());
    if (parsed.firstName || parsed.lastName) {
      contacts.push({
        id: `${fileName}-${index}-${i}`,
        firstName: titleCase(parsed.firstName),
        lastName: titleCase(parsed.lastName),
        email: row['Email'] || '',
        source
      });
    }
  });

  return contacts;
}

function processStandardRow(row: Record<string, string>, fileName: string, index: number): Contact | null {
  const source = row['_sheet'] || fileName;

  // Skip empty rows
  if (!row['Last Name'] && !row['First Name']) return null;

  return {
    id: `${fileName}-${index}`,
    firstName: titleCase(row['First Name'] || ''),
    lastName: titleCase(row['Last Name'] || ''),
    email: row['Email'] || row['Email Address'] || row['E mail'] || '',
    workEmail: row['Work Email'] || '',
    phone: row['Phone'] || '',
    workPhone: row['Work Phone'] || '',
    jobTitle: row['Job Title'] || row['Job Title Description'] || '',
    company: row['Company'] || '',
    source
  };
}