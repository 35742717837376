import { ThemeProvider } from '@/providers/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import { ContactDashboard } from '@/components/contact-dashboard';
import { Header } from '@/components/header';
import { Footer } from '@/components/footer';

export default function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <div className="min-h-screen bg-background flex flex-col">
        <Header />
        <main className="flex-1">
          <ContactDashboard />
        </main>
        <Footer />
      </div>
      <Toaster />
    </ThemeProvider>
  );
}