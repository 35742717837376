import { HomeIcon, UsersIcon } from "lucide-react";
import { ThemeToggle } from "./theme-toggle";
import ContactsOrganizer from "../contacts-organizer.svg";

export function Header() {
  return (
    <header className="border-b bg-card">
      <div className="container mx-auto px-4">
        <div className="h-24 flex items-center justify-between">
          {/* Left side icons */}
          <div className="flex items-center gap-4">
            <a href="/" className="hover:opacity-80 transition-opacity">
              <HomeIcon className="h-6 w-6" />
            </a>
            <a href="/contacts" className="hover:opacity-80 transition-opacity">
              <UsersIcon className="h-6 w-6" />
            </a>
          </div>

          {/* Center logo */}
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <img
              src={ContactsOrganizer}
              alt="Contacts Organizer"
              className="object-contain"
            />
          </div>

          {/* Right side icons */}
          <div className="flex items-center gap-4">
            <ThemeToggle />
          </div>
        </div>
      </div>
    </header>
  );
}
