import { Contact } from './types';
import { normalizeString, normalizePhone } from './string-utils';
import { parseComplexName } from './name-utils';

const CONFIDENCE_THRESHOLDS = {
  HIGH: 0.9,    // 90% confidence for auto-merge
  MEDIUM: 0.7,  // 70% confidence for review
  LOW: 0.5      // Below 50% considered different contacts
};

export function findDuplicateGroups(contacts: Contact[]): Contact[][] {
  const groups: Contact[][] = [];
  const processed = new Set<string>();

  contacts.forEach((contact) => {
    if (processed.has(contact.id)) return;

    const matches = contacts.filter((candidate) => {
      if (candidate.id === contact.id || processed.has(candidate.id)) return false;
      return isPotentialMatch(contact, candidate);
    });

    if (matches.length > 0) {
      const group = [contact, ...matches];
      groups.push(group);
      group.forEach(c => processed.add(c.id));
    }
  });

  return groups;
}

function isPotentialMatch(a: Contact, b: Contact): boolean {
  // Exact email match is a strong indicator
  if (a.email && b.email && normalizeString(a.email) === normalizeString(b.email)) {
    return true;
  }

  // Exact phone match is also strong
  if (a.phone && b.phone && normalizePhone(a.phone) === normalizePhone(b.phone)) {
    return true;
  }

  // Name matching with high confidence
  if (a.firstName && b.firstName && a.lastName && b.lastName) {
    const firstNameMatch = compareNames(a.firstName, b.firstName);
    const lastNameMatch = compareNames(a.lastName, b.lastName);

    // Both names match with high confidence
    if (firstNameMatch >= CONFIDENCE_THRESHOLDS.HIGH && 
        lastNameMatch >= CONFIDENCE_THRESHOLDS.HIGH) {
      return true;
    }

    // Medium confidence match with supporting data
    if (firstNameMatch >= CONFIDENCE_THRESHOLDS.MEDIUM && 
        lastNameMatch >= CONFIDENCE_THRESHOLDS.MEDIUM) {
      // Check for matching company or source
      if (a.source && b.source && normalizeString(a.source) === normalizeString(b.source)) {
        return true;
      }
    }
  }

  return false;
}

function compareNames(name1: string, name2: string): number {
  const norm1 = normalizeString(name1);
  const norm2 = normalizeString(name2);

  if (norm1 === norm2) return 1;

  // Check for nickname matches
  if (isNickname(norm1, norm2)) return 0.9;

  // Calculate string similarity
  return calculateStringSimilarity(norm1, norm2);
}

function calculateStringSimilarity(str1: string, str2: string): number {
  const longer = str1.length > str2.length ? str1 : str2;
  const shorter = str1.length > str2.length ? str2 : str1;

  if (longer.length === 0) return 1.0;

  const costs = new Array(shorter.length + 1);
  for (let i = 0; i <= shorter.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= longer.length; j++) {
      if (i === 0) {
        costs[j] = j;
      } else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (shorter[i - 1] !== longer[j - 1]) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[shorter.length] = lastValue;
  }

  return (longer.length - costs[shorter.length]) / longer.length;
}

export function mergeContacts(contacts: Contact[]): Contact[] {
  const groups = findDuplicateGroups(contacts);
  const mergedContacts: Contact[] = [];
  const processed = new Set<string>();

  // Process each group of duplicates
  groups.forEach(group => {
    const mergedContact = mergeGroup(group);
    mergedContacts.push(mergedContact);
    group.forEach(c => processed.add(c.id));
  });

  // Add remaining unprocessed contacts
  contacts.forEach(contact => {
    if (!processed.has(contact.id)) {
      mergedContacts.push(contact);
    }
  });

  return mergedContacts;
}

function mergeGroup(group: Contact[]): Contact {
  // Start with the most complete contact as base
  const base = findMostComplete(group);
  const merged = { ...base };

  // Track all sources
  const sources = new Set<string>();
  if (base.source) sources.add(base.source);

  // Merge in data from other contacts
  group.forEach(contact => {
    if (contact.id === base.id) return;
    if (contact.source) sources.add(contact.source);

    // Merge each field, keeping the most complete/valid value
    Object.entries(contact).forEach(([key, value]) => {
      if (key === 'id' || key === 'source' || !value) return;

      const currentValue = merged[key as keyof Contact];
      if (!currentValue) {
        merged[key as keyof Contact] = value;
      } else if (currentValue !== value) {
        // Choose the better value based on field type
        merged[key as keyof Contact] = chooseBestValue(
          key as keyof Contact,
          currentValue.toString(),
          value.toString()
        );
      }
    });
  });

  // Combine sources
  if (sources.size > 0) {
    merged.source = Array.from(sources).join(', ');
  }

  return merged;
}

function findMostComplete(contacts: Contact[]): Contact {
  return contacts.reduce((most, current) => {
    const mostFields = Object.values(most).filter(Boolean).length;
    const currentFields = Object.values(current).filter(Boolean).length;
    return currentFields > mostFields ? current : most;
  });
}

function chooseBestValue(
  field: keyof Contact,
  value1: string,
  value2: string
): string {
  switch (field) {
    case 'email':
      // Prefer longer email addresses (usually more complete)
      return value1.length >= value2.length ? value1 : value2;

    case 'phone':
      // Choose the more formatted number
      const phone1 = normalizePhone(value1);
      const phone2 = normalizePhone(value2);
      return phone1.length >= phone2.length ? value1 : value2;

    case 'firstName':
    case 'lastName':
      // Prefer longer names unless one is clearly a nickname
      if (isNickname(value1, value2)) {
        return value1.length > value2.length ? value1 : value2;
      }
      return value1.length >= value2.length ? value1 : value2;

    default:
      // For other fields, prefer non-empty values
      return value1 || value2;
  }
}

const COMMON_NICKNAMES: Record<string, string[]> = {
  'robert': ['bob', 'rob', 'bobby'],
  'william': ['will', 'bill', 'billy'],
  'elizabeth': ['beth', 'liz', 'betty', 'lizzy'],
  'james': ['jim', 'jimmy', 'jamie'],
  'michael': ['mike', 'mikey'],
  'christopher': ['chris', 'topher'],
  'jonathan': ['jon', 'jonny'],
  'nicholas': ['nick', 'nicky'],
  'benjamin': ['ben', 'benny'],
  'alexander': ['alex', 'al', 'sandy'],
  'katherine': ['kate', 'katie', 'kathy'],
  'margaret': ['maggie', 'meg', 'peggy'],
  'steven': ['steve'],
  'matthew': ['matt'],
  'daniel': ['dan', 'danny'],
  'joseph': ['joe', 'joey'],
  'andrew': ['andy', 'drew'],
  'anthony': ['tony'],
  'charles': ['chuck', 'charlie'],
  'richard': ['rick', 'dick', 'rich']
};

function isNickname(name1: string, name2: string): boolean {
  const n1 = normalizeString(name1);
  const n2 = normalizeString(name2);
  
  return Object.entries(COMMON_NICKNAMES).some(([formal, nicknames]) => {
    return (n1 === formal && nicknames.includes(n2)) ||
           (n2 === formal && nicknames.includes(n1)) ||
           (nicknames.includes(n1) && nicknames.includes(n2));
  });
}