import { useState } from 'react';
import { Contact } from '@/lib/types';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import { FileSpreadsheet, FileText, Users } from 'lucide-react';
import { exportContacts } from '@/lib/contact-export';
import { useToast } from '@/hooks/use-toast';

interface ExportDialogProps {
  contacts: Contact[];
  originalContacts: Contact[];
  stats: {
    totalOriginal: number;
    totalMerged: number;
    duplicatesRemoved: number;
    sourcesCombined: number;
  };
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ExportDialog({
  contacts,
  originalContacts,
  stats,
  open,
  onOpenChange,
}: ExportDialogProps) {
  const { toast } = useToast();
  const [format, setFormat] = useState('xlsx');
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleExport = async () => {
    setIsExporting(true);
    setProgress(0);

    try {
      await exportContacts(contacts, format, {
        originalContacts,
        onProgress: (progress) => setProgress(progress),
      });

      toast({
        title: 'Export successful',
        description: `${contacts.length} contacts exported with detailed merge report`,
      });
      onOpenChange(false);
    } catch (error) {
      toast({
        title: 'Export failed',
        description: error instanceof Error ? error.message : 'Unknown error',
        variant: 'destructive',
      });
    } finally {
      setIsExporting(false);
      setProgress(0);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Export Contacts</DialogTitle>
          <DialogDescription>
            Export your contacts with a detailed merge report showing all changes and sources.
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-2 gap-4">
            <Card className="p-4">
              <div className="flex items-center gap-2 mb-2">
                <FileSpreadsheet className="h-4 w-4" />
                <h4 className="font-medium">Original Data</h4>
              </div>
              <div className="space-y-1">
                <div className="text-2xl font-bold">{stats.totalOriginal}</div>
                <div className="text-sm text-muted-foreground">Total contacts</div>
              </div>
              <div className="mt-2 flex gap-2">
                <Badge variant="secondary">{stats.sourcesCombined} sources</Badge>
              </div>
            </Card>

            <Card className="p-4">
              <div className="flex items-center gap-2 mb-2">
                <Users className="h-4 w-4" />
                <h4 className="font-medium">After Merging</h4>
              </div>
              <div className="space-y-1">
                <div className="text-2xl font-bold">{stats.totalMerged}</div>
                <div className="text-sm text-muted-foreground">Unique contacts</div>
              </div>
              <div className="mt-2 flex gap-2">
                <Badge variant="secondary">{stats.duplicatesRemoved} duplicates removed</Badge>
              </div>
            </Card>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Export Format</label>
            <Select value={format} onValueChange={setFormat} disabled={isExporting}>
              <SelectTrigger>
                <SelectValue placeholder="Select format" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="xlsx">
                  <div className="flex items-center gap-2">
                    <FileSpreadsheet className="h-4 w-4" />
                    <div>
                      <div>Excel Workbook</div>
                      <div className="text-sm text-muted-foreground">
                        Includes detailed merge report and source tracking
                      </div>
                    </div>
                  </div>
                </SelectItem>
                <SelectItem value="csv">
                  <div className="flex items-center gap-2">
                    <FileText className="h-4 w-4" />
                    <div>
                      <div>CSV File</div>
                      <div className="text-sm text-muted-foreground">
                        Simple format, merged contacts only
                      </div>
                    </div>
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          {format === 'xlsx' && (
            <div className="rounded-lg border p-4 bg-muted/50">
              <h4 className="font-medium mb-2">Excel Export Includes:</h4>
              <ul className="space-y-2 text-sm">
                <li className="flex items-center gap-2">
                  <Badge variant="outline">Sheet 1</Badge>
                  <span>Merged Contacts - Final clean list</span>
                </li>
                <li className="flex items-center gap-2">
                  <Badge variant="outline">Sheet 2</Badge>
                  <span>Merge Report - Details of all changes made</span>
                </li>
                <li className="flex items-center gap-2">
                  <Badge variant="outline">Sheet 3</Badge>
                  <span>Original Data - All source files combined</span>
                </li>
              </ul>
            </div>
          )}

          {isExporting && (
            <div className="space-y-2">
              <Progress value={progress} />
              <p className="text-sm text-muted-foreground text-center">
                Preparing export with merge report...
              </p>
            </div>
          )}
        </div>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isExporting}
          >
            Cancel
          </Button>
          <Button onClick={handleExport} disabled={isExporting}>
            {isExporting ? 'Exporting...' : 'Export Contacts'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}