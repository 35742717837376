import { useEffect, useState } from 'react';
import { FileSpreadsheet, ScanSearch, Sparkles, GitMerge } from 'lucide-react';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

interface ProcessingStep {
  id: string;
  label: string;
  description: string;
  progress: number;
  status: 'pending' | 'processing' | 'complete';
  icon: React.ElementType;
}

interface ProcessingScreenProps {
  totalContacts: number;
  onComplete: () => void;
}

export function ProcessingScreen({ totalContacts, onComplete }: ProcessingScreenProps) {
  const [steps, setSteps] = useState<ProcessingStep[]>([
    {
      id: 'parse',
      label: 'Reading Contact Data',
      description: `Processing ${totalContacts.toLocaleString()} contacts`,
      progress: 0,
      status: 'pending',
      icon: FileSpreadsheet
    },
    {
      id: 'standardize',
      label: 'Standardizing Formats',
      description: 'Normalizing names, emails, and phones',
      progress: 0,
      status: 'pending',
      icon: Sparkles
    },
    {
      id: 'dedupe',
      label: 'Finding Duplicates',
      description: 'Using smart matching to identify similar contacts',
      progress: 0,
      status: 'pending',
      icon: ScanSearch
    },
    {
      id: 'merge',
      label: 'Merging Records',
      description: 'Combining data from duplicate entries',
      progress: 0,
      status: 'pending',
      icon: GitMerge
    }
  ]);

  useEffect(() => {
    let currentStepIndex = 0;

    const processStep = (stepIndex: number) => {
      const stepDuration = 2000;
      const progressInterval = 50;
      const progressIncrement = 100 / (stepDuration / progressInterval);
      let progress = 0;

      setSteps(current => 
        current.map((step, index) => ({
          ...step,
          status: index === stepIndex ? 'processing' : step.status,
        }))
      );

      const intervalId = setInterval(() => {
        progress += progressIncrement;
        
        setSteps(current =>
          current.map((step, index) => ({
            ...step,
            progress: index === stepIndex ? Math.min(progress, 100) : step.progress,
          }))
        );

        if (progress >= 100) {
          clearInterval(intervalId);
          setSteps(current =>
            current.map((step, index) => ({
              ...step,
              status: index === stepIndex ? 'complete' : step.status,
              progress: index === stepIndex ? 100 : step.progress,
            }))
          );

          currentStepIndex++;
          if (currentStepIndex < steps.length) {
            processStep(currentStepIndex);
          } else {
            setTimeout(onComplete, 1000);
          }
        }
      }, progressInterval);
    };

    setTimeout(() => processStep(0), 500);
  }, [onComplete]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background/95 backdrop-blur-sm">
      <div className="w-full max-w-2xl mx-auto space-y-12 p-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-3">Processing Your Contacts</h2>
          <p className="text-muted-foreground text-lg">
            Please wait while we clean and organize your data
          </p>
        </div>

        <div className="space-y-8">
          {steps.map((step) => (
            <div 
              key={step.id} 
              className={cn(
                "space-y-3 transition-all duration-300",
                step.status === 'processing' ? 'scale-105' : 'scale-100'
              )}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div 
                    className={cn(
                      "p-3 rounded-xl transition-colors",
                      step.status === 'complete' && "bg-accent/10 text-accent",
                      step.status === 'processing' && "bg-accent/5 text-accent animate-pulse",
                      step.status === 'pending' && "bg-muted text-muted-foreground"
                    )}
                  >
                    <step.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="font-semibold text-lg">{step.label}</div>
                    <div className="text-sm text-muted-foreground">
                      {step.description}
                    </div>
                  </div>
                </div>
                <div 
                  className={cn(
                    "text-sm font-medium",
                    step.status === 'complete' && "text-accent",
                    step.status === 'processing' && "text-accent animate-pulse",
                    step.status === 'pending' && "text-muted-foreground"
                  )}
                >
                  {step.progress.toFixed(0)}%
                </div>
              </div>
              
              <Progress 
                value={step.progress} 
                className={cn(
                  "h-2",
                  step.status === 'complete' && "bg-accent/10",
                  step.status === 'processing' && "bg-accent/5"
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}