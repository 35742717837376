import { useCallback, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Contact } from '@/lib/types';
import { Badge } from './ui/badge';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Search, Download, Filter } from 'lucide-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

interface ContactsGridProps {
  contacts: Contact[];
  onExport?: () => void;
}

export function ContactsGrid({ contacts, onExport }: ContactsGridProps) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchText, setSearchText] = useState('');

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    minWidth: 100,
    floatingFilter: true,
  }), []);

  const columnDefs = useMemo<ColDef[]>(() => [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 48,
      pinned: 'left'
    },
    {
      headerName: 'Name',
      children: [
        { 
          field: 'firstName',
          headerName: 'First Name',
          minWidth: 120,
          filter: 'agTextColumnFilter',
        },
        { 
          field: 'lastName',
          headerName: 'Last Name',
          minWidth: 120,
          filter: 'agTextColumnFilter',
        }
      ]
    },
    {
      headerName: 'Contact',
      children: [
        { 
          field: 'email',
          headerName: 'Email',
          minWidth: 200,
          filter: 'agTextColumnFilter',
        },
        { 
          field: 'workEmail',
          headerName: 'Work Email',
          minWidth: 200,
          filter: 'agTextColumnFilter',
        },
        { 
          field: 'phone',
          headerName: 'Phone',
          minWidth: 150,
          filter: 'agTextColumnFilter',
        },
        { 
          field: 'workPhone',
          headerName: 'Work Phone',
          minWidth: 150,
          filter: 'agTextColumnFilter',
        }
      ]
    },
    {
      headerName: 'Professional',
      children: [
        { 
          field: 'jobTitle',
          headerName: 'Job Title',
          minWidth: 180,
          filter: 'agTextColumnFilter',
        },
        { 
          field: 'company',
          headerName: 'Company',
          minWidth: 180,
          filter: 'agTextColumnFilter',
        }
      ]
    },
    {
      headerName: 'Source',
      field: 'source',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => {
        if (!params.value) return '-';
        return (
          <div className="flex gap-1 flex-wrap">
            {params.value.split(',').map((source: string, i: number) => (
              <Badge key={i} variant="outline" className="text-xs">
                {source.trim()}
              </Badge>
            ))}
          </div>
        );
      }
    }
  ], []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const onSearchChange = useCallback((value: string) => {
    setSearchText(value);
    if (gridApi) {
      gridApi.setQuickFilter(value);
    }
  }, [gridApi]);

  const clearFilters = useCallback(() => {
    if (gridApi) {
      gridApi.setFilterModel(null);
      setSearchText('');
    }
  }, [gridApi]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between gap-4">
        <div className="relative flex-1 max-w-sm">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search contacts..."
            value={searchText}
            onChange={(e) => onSearchChange(e.target.value)}
            className="pl-9"
          />
        </div>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" onClick={clearFilters}>
            <Filter className="h-4 w-4 mr-2" />
            Clear Filters
          </Button>
          {onExport && (
            <Button size="sm" onClick={onExport}>
              <Download className="h-4 w-4 mr-2" />
              Export Selected
            </Button>
          )}
        </div>
      </div>

      <div className="ag-theme-alpine h-[600px] w-full rounded-md border">
        <AgGridReact
          rowData={contacts}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          enableRangeSelection={true}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          suppressCellFocus={true}
          animateRows={true}
          pagination={true}
          paginationAutoPageSize={true}
          rowGroupPanelShow="always"
          statusBar={{
            statusPanels: [
              { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
              { statusPanel: 'agSelectedRowCountComponent' },
            ],
          }}
        />
      </div>
    </div>
  );
}